import React, { useState } from "react"
import Layout from "./layout";
import { Container } from "@material-ui/core";
import styled from "@emotion/styled";
import { FadingImages } from "./FadingImages";
import { PageHeader } from "./PageHeader";
import { BirthdayDatePicker } from "./BirthdayDatePicker";

const BackLinkWrapper = styled.div`
  text-align: center;
  padding: 10px;
`;

interface Props {
  header: string;
  images: string[];
  minAge?: number;
}

type AgeVerifyStatus = "pending" | "ok" | "denied";

const ImageWrapper = styled.div`

  width: 100%;
  height: calc(100vh - 240px);
  
  display: flex;
  flex-direction: columns;
  align-items: center;
  
  img {
    margin: 0 auto;
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-width: 800px;
    max-height: 600px;
  }
  
`;

const GrowUpWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 5rem 0;
  font-size: 30pt;
`;

const BithdayWrapper = styled.div`
  margin-bottom: 3rem;
`;

const WhatLayout: React.FC<Props> = props => {

  const [ageVerifyStatus, setAgeVerifyStatus] = useState<AgeVerifyStatus>( () => {
    return !!props.minAge ? "pending" : "ok";
  } );

  const handleBirthdayProvided = ( age: number ) => {
    setAgeVerifyStatus( age >= (props.minAge || 0) ? "ok" : "denied" );
  };

  return (
    <Layout>
      <Container maxWidth="md">

        <PageHeader>
          {props.header}
        </PageHeader>

        {ageVerifyStatus === "ok" && (
          <ImageWrapper>
            <FadingImages images={props.images}/>
          </ImageWrapper>
        )}

        {ageVerifyStatus === "pending" && (
          <BithdayWrapper>
            <BirthdayDatePicker
              onBithdayProvided={handleBirthdayProvided}
            />
          </BithdayWrapper>
        )}

        {ageVerifyStatus === "denied" && (
          <GrowUpWrapper>
            Grow up and try again later! ]:)
          </GrowUpWrapper>
        )}

        <BackLinkWrapper>
          <a href={"/what"}>
            &gt;&gt; back &lt;&lt;
          </a>
        </BackLinkWrapper>

      </Container>
    </Layout>
  );

};

export default WhatLayout;
