import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useInterval } from "react-use";

interface Props {
  images: string[];
  width?: number;
  height?: number;
  alt?: string;
  showDuration?: number;
  fadeDuration?: number;
  className?: string;
}

interface State {
  show: boolean;
  index: number | null;
}

const FadingImages: React.FunctionComponent<Props> = props => {

  const [state, setState] = useState<State>( { show: false, index: null } );

  const proceed = useCallback( () => {
    setState( prev => {

      // hide it now
      if( prev.show ) {
        return {
          show: false,
          index: prev.index
        }
      }

      // show the next one
      else {
        return {
          show: true,
          index: prev.index !== null
            ? ((prev.index + 1) % props.images.length)
            : 0
        };
      }

    } );
  }, [props.images] );

  useEffect( () => {

    // render once with opacticy 0 to get a fade in effect
    setTimeout( proceed, 1 );

  }, [] );

  const showDuration = props.showDuration || 4000;
  const fadeDuration = props.fadeDuration || 1000;
  const currentDuration = state.show ? showDuration : fadeDuration;

  useInterval( proceed, currentDuration );

  const currentIndex = state.index || 0;
  const currentImage = props.images[currentIndex];
  const nextImage = props.images[(currentIndex + 1) % props.images.length];

  return (
    <Fragment>

      {/* The current image */}
      <img
        src={currentImage}
        className={props.className}
        style={{
          opacity: state.show ? 1 : 0,
          transition: `opacity ${fadeDuration}ms ease-in-out`
        }}
        alt={props.alt || "image"}
        width={props.width}
        height={props.height}
      />

      {/* The next image to let the browser prefetch it */}
      <img
        src={nextImage}
        style={{
          display: "none"
        }}
      />

    </Fragment>
  );

};

export { FadingImages };