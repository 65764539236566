/** @jsx jsx */
import React, { useState } from "react"
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import SEO from "./seo";
import Drawer from "@material-ui/core/Drawer";
import { jsx } from "@emotion/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ButtonBase, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import MenuIcon from '@material-ui/icons/Menu';
import { navigate } from "gatsby"
import IconButton from "@material-ui/core/IconButton";
import { muiTheme } from "../theme";

interface Props {
}

const Layout: React.FunctionComponent<Props> = ( { children } ) => {

  const [drawerOpen, setDrawerOpen] = useState( false );

  const navigateTo = ( target: string ) => {
    setDrawerOpen( false );
    navigate( target );
  };

  return (
    <React.Fragment>

      <SEO title="www.jacks-heart.com"/>

      <ThemeProvider theme={muiTheme}>

        <Drawer
          open={drawerOpen}
          onClose={() => setDrawerOpen( false )}
          hideBackdrop={false}
        >

          <Box
            mt={2} ml={2} mr={2}
            color={muiTheme.palette.text.secondary}
          >

            <ButtonBase onClick={() => navigateTo( "/" )}>
              <Typography variant="h6">
                Jack's HEart
              </Typography>
            </ButtonBase>

            <List>

              <ListItem button onClick={() => navigateTo( "/who" )}>
                <ListItemText>
                  Who
                </ListItemText>
              </ListItem>

              <ListItem button onClick={() => navigateTo( "/what" )}>
                <ListItemText>
                  What
                </ListItemText>
              </ListItem>

              <ListItem button onClick={() => navigateTo( "/why" )}>
                <ListItemText>
                  Why
                </ListItemText>
              </ListItem>

              <ListItem button onClick={() => navigateTo( "/where" )}>
                <ListItemText>
                  Where
                </ListItemText>
              </ListItem>

              <ListItem button onClick={() => navigateTo( "/find" )}>
                <ListItemText>
                  Find
                </ListItemText>
              </ListItem>

{/*
              <ListItem button>
                <ListItemText>
                  Change
                </ListItemText>
              </ListItem>

              <ListItem button>
                <ListItemText>
                  Stay
                </ListItemText>
              </ListItem>
*/}

              <ListItem button onClick={() => window.location.href = "https://shop.jacks-heart.com/"}>
                <ListItemText>
                  Buy
                </ListItemText>
              </ListItem>

              <ListItem button onClick={() => navigateTo( "/impress" )}>
                <ListItemText>
                  Impress
                </ListItemText>
              </ListItem>

            </List>

          </Box>
        </Drawer>

        <IconButton edge="start" color="primary" onClick={() => setDrawerOpen( true )}>
          <MenuIcon/>
        </IconButton>

        {children}

      </ThemeProvider>
    </React.Fragment>
  );

};

export default Layout
