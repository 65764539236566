import React, { useState } from "react";
import styled from "@emotion/styled";

interface Props {
  onBithdayProvided: ( age: number ) => void;
}

const Wrapper = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  
  p {
    font-size: 20pt;
  }
  
  input {
    font-size: 18pt
  }
  
  button {
    margin-top: 1rem;
    font-size: 20pt
    border: 1px solid white;
    background-color: black;
    padding: 5px 10px;
    color: white;
    &:disabled {
      color: grey;
    }
  }
  
`;

const BirthdayDatePicker: React.FunctionComponent<Props> = props => {

  const [selectedDate, setSelectedDate] = useState<string>( "" );

  const handleOk = () => {

    if( !!selectedDate ) {

      const birthday = new Date( selectedDate );

      // dirty way of calculating the date
      const fromEpoch = new Date( Date.now() - birthday.getTime() );
      const age = fromEpoch.getUTCFullYear() - 1970;

      props.onBithdayProvided( age );

    }

  };

  return (
    <Wrapper>

      <p>
        Enter your bithday:
      </p>

      <input
        type="date"
        value={selectedDate}
        onChange={e => setSelectedDate( e.target.value )}
      />

      <button
        onClick={handleOk}
        disabled={!selectedDate}
      >
        Done
      </button>

    </Wrapper>
  );

};

export { BirthdayDatePicker };