import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { grey } from "@material-ui/core/colors";

export const muiTheme = createMuiTheme( {
  palette: {
    primary: grey,
    secondary: grey,
    text: {
      primary: grey["200"],
      secondary: grey["900"]
    },
  },
  typography: {
    fontFamily: "'Courier Prime', monospace",
    fontSize: 14
  }
} );